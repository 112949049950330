@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Courier+Prime:wght@400;700&display=swap');
@import 'variables';

html {
  font-size: 16px;
}

body {
  background: $color-primary-dark;
  color: $color-primary-light;
  font-family: $font-primary;
  font-size: 1rem;
}

img {
  width: 100%;
}

a {
  color: $color-secondary-light;
  text-decoration: underline;

  &:hover {
    color: $color-tertiary-light;
    text-decoration: none;
  }
}

h1 {
  font-family: $font-secondary;
  font-size: 2.5rem;
  text-align: center;
  padding-bottom: 1rem;
  margin: 0;
}

h2, h3, h4 {
  font-family: $font-secondary;
  padding: 1rem 0;
  margin: 0;
}

header {
  width: 100%;
  padding: 2rem 0;
  margin: 0 0 2rem;
  text-align: center;
  font-weight: bold;
  border-bottom: $border-pattern;

  .image {
    display: inline-block;
    width: 100%;
    max-width: 50px;
  }

  .title {
    font-size: 3rem;
  }

  .description {
    font-size: 1.2rem;
  }
}

footer {
  width: 100%;
  padding: 2rem 0;
  margin: 2rem 0 0;
  text-align: center;
  font-weight: bold;
  border-top: $border-pattern;
}

ul {
  padding-left: 0;
  list-style: none;
}

.home {
  .image {
    margin: 0 auto 1rem;
    max-width: 300px;
  }

  h2 {
    a {
      text-decoration: none;
    }
  }

  .intro {
    &.row {
      border-bottom: $border-pattern;
    }
  }
}

.post-meta {
  padding: 0 0 2rem;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.highlighter-rouge {
  color: $color-secondary-light;
}

.table-of-contents, .menu-link {
  font-size: 1.5rem;
  font-weight: bold;
}

.post-nav {
  padding-top: 2rem;
  font-weight: bold;
  font-size: 1.2rem;

  .prev {
    text-align: left;
  }
  .next {
    text-align: right;
  }
}

.carousel-inner {
  .position {
    font-size: 2rem;
    background: $color-primary-light;
    color: $color-primary-dark;
    text-align: center;
    padding: 0.5rem 0;
    margin: 0 0 1rem;
  }

  .title {
    font-size: 2.5rem;
    font-weight: bold;
    padding: 0 0 1rem;
  }

  .image {
    overflow: hidden;
    width: 100%;
    max-width: 300px;
    max-height: 200px;
    margin: 0 auto;

    &.square {
      max-height: 300px;
    }
  }

  .caption {
    font-size: 1.7rem;
    font-style: italic;
    padding: 1rem 0 3rem;
  }

  .icon {
    margin: 1rem auto 3rem;
    width: 100px;
    height: 100px;

    &.spotify:hover {
      cursor: pointer;
    }
  }
}
#albums-carousel {
  .image:hover {
    cursor: pointer;
  }
}

.modal {
  .btn-secondary {
    background: $color-primary-light;
    color: $color-primary-dark;
  }

  .modal-content {
    background: $color-primary-dark;
  }
}

.blockquote {
  .quote-container {
    margin: 0 auto;
    width: 80%;
    background: $color-primary-light;
    color: $color-primary-dark;

    .quotation-mark {
      font-size: 3rem;
    }

    .quote-body {
      padding: 0 2rem;
    }

    .open-quote {
      text-align: left;
      padding-left: 2rem;
    }

    .close-quote {
      text-align: right;
      padding-right: 2rem;
    }
  }

  .quote-source {
    padding: 1rem;
    font-style: italic;
  }
}

@media screen and  (min-width: 550px) {
  html { font-size: 18px; }

  .carousel-inner {
    .image {
      max-width: 100%;
      max-height: 100%;
      width: 400px;
      height: 250px;

      &.square {
        max-height: inherit;
        height: 400px;
      }
    }
  }
}

@media screen and  (min-width: 768px) {
  .blockquote {
    .quote-container {
      width: auto;
      margin: 0 5rem;
    }
  }
}

@media screen and (min-width: 992px) {
  html { font-size: 20px; }

  .carousel-inner {
    .image {
      width: 500px;
      height: 350px;

      &.square {
        height: 500px;
      }
    }
  }
}
